<template>
  <div :class="`alert alert-${type}`">
    {{ output }}
    <slot />
  </div>
</template>

<script>

import { useI18n } from 'vue-i18n'
import { getLocalizedOrValue } from '@/application/utils/localization'

export default {
  name: 'Alert',
  props: {
    text: String,
    type: String,
  },
  setup(props) {
    const i18n = useI18n()
    const output = getLocalizedOrValue(i18n, props.text, props.text)
    return {
      output,
    }
  },
}
</script>

<style scoped>

</style>
